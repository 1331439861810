import { Fragment, ReactElement, useCallback, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, HelpIcon, Popover, Tooltip } from '@breathelife/mui';
import { NotificationStatus, Notification, ConsumerFeedbackProvider } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import { Drawer } from '../../../Components/Drawer/Drawer';
import { CloseButton } from '../../../Components/Button/CloseButton';
import { useTheme } from '../../../Styles/themed-styled-components';
import { StyledBadge } from '../../../Components/Badge/Badge';
import { LeadPlatformTheme } from '../../../Styles/Types';
import { useSelector, useCarrierContext, useLocale } from '../../../Hooks';
import { getUserDisplayName } from '../../../Helpers/user';
import { NotificationList } from './NotificationList/NotificationList';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { useUpdateAllNotificationsMutation } from '../../../ReactQuery/NotificationCentre/notifications.mutations';
import { NotificationCentreHelp } from './NotificationCentreHelp/NotificationCentreHelp';
import { NotificationCenterContext } from './NotificationCentreContextProvider';
import {
  Actions,
  DrawerCloseButton,
  FeedbackIcon,
  HeaderContent,
  HelpButton,
  HelpPopoverContent,
  HelpPopoverHeader,
  PopoverCloseButtonContainer,
  PopoverContent,
  RightSideContent,
  Title,
  LoadMoreContainer,
} from './Styles';

export function NotificationCentreDrawer(): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const locale = useLocale();

  const DEFAULT_MAX_DAYS = 30;
  const maxDays =
    features.notificationCentre.enabled && features.notificationCentre.maxDays
      ? features.notificationCentre.maxDays
      : DEFAULT_MAX_DAYS;

  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const {
    notifications,
    unreadNotificationsCount,
    isFetchingNotifications,
    refetchNotifications,
    setNotifications,
    isNotificationCentreOpen,
    setIsNotificationCentreOpen,
    canLoadMoreNotifications,
  } = useContext(NotificationCenterContext);

  const [isMarkAllAsReadModalOpen, setIsMarkAllAsReadModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [helpPopoverAnchorEl, setHelpPopoverAnchorEl] = useState(null);
  const [markAllAsReadPopoverAnchorEl, setMarkAllAsReadPopoverAnchorEl] = useState(null);

  const { mutate: mutateNotifications, isLoading: isLoadingNotifications } = useUpdateAllNotificationsMutation({
    onSuccess: () => {
      const updatedNotifications = notifications.map((notification) => {
        return { ...notification, status: NotificationStatus.Read };
      });
      setNotifications(updatedNotifications);
    },
  });

  const consumerFeedbackSurveyLink = useMemo(() => {
    return features.consumerFeedback.enabled &&
      features.consumerFeedback.provider === ConsumerFeedbackProvider.DELIGHTED &&
      !!features.consumerFeedback.delighted?.notificationCentre
      ? features.consumerFeedback.delighted?.notificationCentre.link
      : '';
  }, [features.consumerFeedback]);

  const unreadNotificationsLabel = useMemo(() => {
    if (unreadNotificationsCount === 0) {
      return t('notificationCentre.unreadNotificationsBadge.zero');
    }
    if (unreadNotificationsCount === 1) {
      return t('notificationCentre.unreadNotificationsBadge.singular');
    }
    return t('notificationCentre.unreadNotificationsBadge.plurial', { value: unreadNotificationsCount });
  }, [t, unreadNotificationsCount]);

  const handleMarkAllAsReadPopoverClick = useCallback((e: any) => {
    setMarkAllAsReadPopoverAnchorEl(e.currentTarget);
    setIsMarkAllAsReadModalOpen(true);
  }, []);

  const handleMarkAllAsReadPopoverClose = useCallback(() => {
    setMarkAllAsReadPopoverAnchorEl(null);
    setIsMarkAllAsReadModalOpen(false);
  }, []);

  const handleHelpPopoverClick = useCallback((e: any) => {
    setHelpPopoverAnchorEl(e.currentTarget);
    setHelpModalOpen(true);
  }, []);

  const handleHelpPopoverClose = useCallback(() => {
    setHelpPopoverAnchorEl(null);
    setHelpModalOpen(false);
  }, []);

  const markAllNotificationsAsRead = useCallback(() => {
    if (!user) return;
    mutateNotifications({ data: { status: NotificationStatus.Read } });
    handleMarkAllAsReadPopoverClose();
  }, [mutateNotifications, user, handleMarkAllAsReadPopoverClose]);

  const updateNotificationList = useCallback(
    (notification: Notification) => {
      if (!notifications) return;
      const newList = notifications.map((oldNotification) =>
        oldNotification.id === notification.id ? notification : oldNotification,
      );
      setNotifications(newList);
    },
    [notifications],
  );

  return (
    <Fragment>
      <Drawer open={isNotificationCentreOpen} onClose={() => setIsNotificationCentreOpen(false)}>
        <HeaderContent>
          <div>
            <Title variant='h1'>{t('notificationCentre.title')}</Title>
            {user && (
              <Title variant='body3' component='p' grey={60}>
                {t('notificationCentre.welcomeMessage', { name: getUserDisplayName(user) })}
              </Title>
            )}
            <StyledBadge
              backgroundColor={theme.colors.blue[20]}
              textColor={theme.colors.blue[50]}
              label={unreadNotificationsLabel}
            />
            <Tooltip title={t(`notificationCentre.helpMessage`)} arrow>
              <HelpButton onClick={handleHelpPopoverClick}>
                <HelpIcon />
              </HelpButton>
            </Tooltip>
            <Popover
              anchorEl={helpPopoverAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isHelpModalOpen}
              onClose={handleHelpPopoverClose}
            >
              <HelpPopoverContent>
                <HelpPopoverHeader>
                  <Title variant='h3'>{t('notificationCentre.helpMessage')}</Title>
                  <CloseButton onClick={handleHelpPopoverClose} />
                </HelpPopoverHeader>
                <NotificationCentreHelp />
              </HelpPopoverContent>
            </Popover>
          </div>
          <RightSideContent>
            <DrawerCloseButton onClick={() => setIsNotificationCentreOpen(false)} />
            <Actions>
              {consumerFeedbackSurveyLink && (
                <FeedbackIcon
                  onClick={() =>
                    window.open(consumerFeedbackSurveyLink + '?locale=' + locale, '_blank', 'noopener noreferrer')
                  }
                >
                  <Icon
                    name='notificationFeedbackIcon'
                    variant='grey'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width='24px'
                    height='24px'
                  />
                </FeedbackIcon>
              )}
              <Button color='primary' variant='outlined' onClick={handleMarkAllAsReadPopoverClick}>
                {t('notificationCentre.markAllAsRead')}
              </Button>
              <Popover
                anchorEl={markAllAsReadPopoverAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isMarkAllAsReadModalOpen}
                onClose={handleMarkAllAsReadPopoverClose}
              >
                <PopoverContent>
                  <PopoverCloseButtonContainer>
                    <CloseButton onClick={handleMarkAllAsReadPopoverClose} />
                  </PopoverCloseButtonContainer>
                  <p>{t('notificationCentre.markAllAsReadPopover.content')}</p>
                  {user ? (
                    <SubmitButton
                      onClick={markAllNotificationsAsRead}
                      disabled={isLoadingNotifications || unreadNotificationsCount === 0}
                    >
                      {t('notificationCentre.markAllAsReadPopover.confirmButton')}
                    </SubmitButton>
                  ) : (
                    <Loader />
                  )}
                </PopoverContent>
              </Popover>
            </Actions>
          </RightSideContent>
        </HeaderContent>

        {!isFetchingNotifications ? (
          <NotificationList notifications={notifications} updateNotificationList={updateNotificationList} />
        ) : (
          <Loader />
        )}
        <LoadMoreContainer>
          {canLoadMoreNotifications ? (
            <Button onClick={refetchNotifications}>{t('notificationCentre.loadMoreNotifications')}</Button>
          ) : (
            <p>{t('notificationCentre.allLatestNotificationsLoaded', { maxDays })}</p>
          )}
        </LoadMoreContainer>
      </Drawer>
    </Fragment>
  );
}
