import { ReactElement, useContext } from 'react';

import { Box, IconButton, Badge } from '@breathelife/mui';
import { ImageTypes } from '@breathelife/ui-components';

import styled, { css } from '../../../Styles/themed-styled-components';
import { Icon } from '../../../Components/Icons';
import { NotificationCenterContext } from './NotificationCentreContextProvider';

const HideMobileStyle = css`
  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    display: none;
  }
`;

const IconBox = styled(Box)<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  button {
    padding: 20px;

    &.Mui-focusVisible {
      outline: ${(props) => `1px solid ${props.theme.colors.selection.border.focus}}`};
    }
  }

  ${(props) => (!props.isMobile ? HideMobileStyle : undefined)}
`;

const bellIcon = (
  <Icon
    name='notificationBellIcon'
    variant='grey'
    stroke='none'
    imageType={ImageTypes.decorative}
    width='27px'
    height='27px'
  />
);

export function NotificationCentreIcon(props: { isMobile?: boolean }): ReactElement {
  const { isMobile } = props;
  const { unreadNotificationsCount, isNotificationCentreOpen, setIsNotificationCentreOpen } =
    useContext(NotificationCenterContext);

  const showBadgeContent = !!(unreadNotificationsCount && unreadNotificationsCount > 0);

  return (
    <IconBox isMobile={isMobile}>
      <IconButton onClick={() => setIsNotificationCentreOpen(!isNotificationCentreOpen)}>
        {showBadgeContent ? (
          <Badge
            badgeContent={unreadNotificationsCount >= 9 ? '9+' : unreadNotificationsCount.toString()}
            color='error'
          >
            {bellIcon}
          </Badge>
        ) : (
          bellIcon
        )}
      </IconButton>
    </IconBox>
  );
}
