import { useSelector } from '../../../../Hooks';
import { Box } from '@breathelife/mui';

import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsEntity, ProductsWidgetFeatureType } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import { DownloadPdfButton } from '../DownloadPdfButton/DownloadPdfButton';
import { ProductWidget } from '../ProductWidget/ProductWidget';
import { ApplicationDetailsWidgetViewContainer, StyledActionGhostIconButton } from '../Styles';
import { TotalPremiumsWidget } from '../TotalPremiumsWidget/TotalPremiumsWidget';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { ComprehensivePricingWidget } from '../ComprehensivePricingWidget/ComprehensivePricingWidget';

type Props = {
  selectedProductId: string;
  isApplicationSubmitted: boolean;
  isApplicationSigned: boolean;
  onOpenSubmissionDetailsModal: () => void;
  productsEntity: ProductsEntity<string> | undefined;
};

export function ApplicationDetailsWidgetsContainer(props: Props): ReactElement {
  const { isLoadingProducts, isLoadingQuotes } = useSelector((store) => store.leadPlatform.products);

  const { onOpenSubmissionDetailsModal, productsEntity, isApplicationSubmitted, isApplicationSigned } = props;
  const { t } = useTranslation();
  const { productWidgetType } = useAssistedApplicationContext();

  const isLoadingWidgetData = isLoadingProducts || isLoadingQuotes;

  return (
    <ApplicationDetailsWidgetViewContainer display='flex' flexDirection='column'>
      {productWidgetType === ProductsWidgetFeatureType.comprehensive && <ComprehensivePricingWidget />}
      {productWidgetType === ProductsWidgetFeatureType.default && (
        <Box mb={2}>
          <ProductWidget productsEntity={productsEntity} isLoading={isLoadingWidgetData} />
        </Box>
      )}
      {productWidgetType === ProductsWidgetFeatureType.totalPremiums && (
        <Box mb={2}>
          <TotalPremiumsWidget />
        </Box>
      )}
      {isApplicationSubmitted && isApplicationSigned && (
        <Fragment>
          <DownloadPdfButton iconName='download' mb={1} fullWidth>
            {t('assistedApplication.downloadApplicationPdf')}
          </DownloadPdfButton>
          <Box>
            <StyledActionGhostIconButton
              onClick={onOpenSubmissionDetailsModal}
              startIcon={<Icon imageType={ImageTypes.decorative} name='loginKey' variant='primary' fill='none' />}
              removeIconBorder
              $fullWidth
              size='x-small'
            >
              {t('assistedApplication.seeSubmissionDetails')}
            </StyledActionGhostIconButton>
          </Box>
        </Fragment>
      )}
    </ApplicationDetailsWidgetViewContainer>
  );
}
