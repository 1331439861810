import { ReactElement, Fragment, useState, useMemo, useEffect } from 'react';

import { LoaderOverlay, ImageTypes } from '@breathelife/ui-components';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  ComprehensivePricingCardContainer,
  ComprehensivePricingCardHeader,
  ComprehensivePricingCardFooter,
  ComprehensivePricingCardFooterContainer,
  ComprehensivePricingCardBody,
  ComprehensivePricingContractProductsContainer,
  ComprehensivePricingSubtotalContainer,
} from './Styles';

import { useLocale, useSelector } from '../../../../Hooks';
import Typography from '../../../Typography';
import { Box, Select, MenuItem } from '@breathelife/mui';
import {
  ComprehensivePricingContract,
  ComprehensivePricingParty,
  ComprehensivePricingProduct,
  ComprehensivePricingRider,
  ComprehensivePricingCurrency,
  ComprehensivePricingSupportedPaymentModes,
  ComprehensivePricingAPIReturnValueErrors,
} from '@breathelife/types';

import { Icon } from '../../../Icons';

import {
  ComprehensivePricingWidgetProductCard,
  ComprehensivePricingWidgetTooltipType,
} from './ComprehensivePricingWidgetProductCard';
import { costToCostWithCurrency, formatPartyRole, paymentModeToModeToCost } from './helpers';
import { useTheme } from '../../../../Styles/themed-styled-components';

function TimeUnit(props: { paymentMode: ComprehensivePricingSupportedPaymentModes }): ReactElement {
  let text = '';
  switch (props.paymentMode) {
    case ComprehensivePricingSupportedPaymentModes.annual:
      text = t('assistedApplication.comprehensivePricingWidget.year');
      break;
    case ComprehensivePricingSupportedPaymentModes.quarterly:
      text = t('assistedApplication.comprehensivePricingWidget.quarter');
      break;
    case ComprehensivePricingSupportedPaymentModes.monthly:
      text = t('assistedApplication.comprehensivePricingWidget.month');
      break;
    case ComprehensivePricingSupportedPaymentModes.semiAnnual:
      text = t('assistedApplication.comprehensivePricingWidget.halfYear');
      break;
    case ComprehensivePricingSupportedPaymentModes.singlePremium:
      text = t('assistedApplication.comprehensivePricingWidget.year');
      break;
  }
  return <Fragment>{text}</Fragment>;
}

function ComprehensivePricingPaymentModeSelector(props: {
  supportedPaymentModes: ComprehensivePricingSupportedPaymentModes[];
  value: ComprehensivePricingSupportedPaymentModes;
  onChange: (paymentMode: ComprehensivePricingSupportedPaymentModes) => void;
}): ReactElement {
  const { t } = useTranslation();
  const paymentModeOptions = useMemo(
    () =>
      Object.values(ComprehensivePricingSupportedPaymentModes)
        .map((mode) => ({
          label: t(`assistedApplication.comprehensivePricingWidget.paymentModes.${mode}`),
          value: mode,
        }))
        .filter((mode) => props.supportedPaymentModes.indexOf(mode.value) > -1),
    [],
  );

  return (
    <Select
      labelId='demo-simple-select-label'
      id='demo-simple-select'
      value={props.value}
      displayEmpty
      onChange={(e) => {
        props.onChange(e.target.value as ComprehensivePricingSupportedPaymentModes);
      }}
    >
      {paymentModeOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export function ComprehensivePricingWidget(): ReactElement {
  const isError = false;
  const { isLoadingComprehensivePricing: isFetching, comprehensivePricing } = useSelector(
    (state) => state.leadPlatform.products,
  );

  const [paymentMode, setPaymentMode] = useState<ComprehensivePricingSupportedPaymentModes>(
    ComprehensivePricingSupportedPaymentModes.monthly,
  );
  const theme = useTheme();

  const comprehensivePricingValue = comprehensivePricing?._tag === 'success' ? comprehensivePricing.value : null;

  useEffect(() => {
    if (
      comprehensivePricingValue?.supportedPaymentModes &&
      comprehensivePricingValue.supportedPaymentModes.indexOf(paymentMode) < 0
    ) {
      setPaymentMode(comprehensivePricingValue.supportedPaymentModes[0]);
    }
  }, [comprehensivePricingValue?.supportedPaymentModes, paymentMode]);

  const language = useLocale();
  return (
    <ComprehensivePricingCardContainer>
      <ComprehensivePricingCardHeader>
        <Typography variant='h3' component='h2'>
          {t('assistedApplication.comprehensivePricingWidget.title')}
        </Typography>
        {comprehensivePricingValue && (
          <Box sx={{ flexDirection: 'column', alignItems: 'flex-end', display: 'flex' }}>
            <Typography variant='small1'>
              {t('assistedApplication.comprehensivePricingWidget.paymentModeDropdownLabel')}
            </Typography>
            {comprehensivePricingValue.supportedPaymentModes.length > 1 && (
              <ComprehensivePricingPaymentModeSelector
                supportedPaymentModes={comprehensivePricingValue.supportedPaymentModes}
                value={paymentMode}
                onChange={setPaymentMode}
              />
            )}
          </Box>
        )}
      </ComprehensivePricingCardHeader>
      <LoaderOverlay isLoading={isFetching}></LoaderOverlay>

      <ComprehensivePricingCardBody>
        {(isError || comprehensivePricing._tag === 'error') && (
          <Box>
            <Typography variant='body1' color={theme.colors.grey[60]}>
              {t(
                comprehensivePricing._tag === 'error' &&
                  comprehensivePricing.error === ComprehensivePricingAPIReturnValueErrors.missingOwner
                  ? 'assistedApplication.comprehensivePricingWidget.fetchComprehensivePricingMissingOwnerError'
                  : 'assistedApplication.comprehensivePricingWidget.fetchComprehensivePricingMissingInformationError',
              )}
            </Typography>
          </Box>
        )}
        {!isError && comprehensivePricingValue && !comprehensivePricingValue.contracts?.length && (
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Icon name='documentWithCheck' imageType={ImageTypes.decorative} />
            <Box mt={2}>
              <Typography variant='body1' color={theme.colors.grey[60]}>
                {t('assistedApplication.productWidget.empty')}
              </Typography>
            </Box>
          </Box>
        )}
        {!isError && comprehensivePricingValue && (
          <Box>
            {comprehensivePricingValue.contracts.map((contract: ComprehensivePricingContract, contractIndex) => {
              const showContractSection = contract.riders.length || (contract.fees && !contract.fees.hideFees);
              return (
                <Box key={1} mt={contractIndex === 0 ? 0 : 3}>
                  {contract.parties.map((party: ComprehensivePricingParty) => (
                    <Fragment key={`${party.firstName} ${party.lastName}`}>
                      <Box mb={showContractSection ? 3 : 0}>
                        <Typography variant='h3' component='h2'>
                          {`${party.firstName} ${party.lastName} (${formatPartyRole(party.roles)})`}
                        </Typography>

                        <ComprehensivePricingContractProductsContainer>
                          {party.products.map((product: ComprehensivePricingProduct, index) => (
                            <ComprehensivePricingWidgetProductCard
                              key={product.productCode}
                              displayName={product.displayName?.[language] || product.productCode}
                              cost={paymentModeToModeToCost(
                                paymentMode,
                                {
                                  monthlyCost: product.monthlyCost,
                                  annualCost: product.annualCost,
                                  quarterlyCost: product.quarterlyCost,
                                  semiAnnualCost: product.semiAnnualCost,
                                  singlePremiumCost: product.singlePremiumCost,
                                },
                                ComprehensivePricingCurrency.CAD,
                              )}
                              isDuplicateCost={product.isDuplicateCost}
                              type={'product'}
                              coverageAmount={product.coverageAmount}
                              currency={ComprehensivePricingCurrency.CAD}
                              isFirstInList={index === 0}
                              isJointProduct={product.isJointProduct}
                            >
                              {product.riders.map((rider: ComprehensivePricingRider) => (
                                <ComprehensivePricingWidgetProductCard
                                  key={rider.productCode}
                                  displayName={rider.displayName?.[language] || rider.productCode}
                                  cost={paymentModeToModeToCost(
                                    paymentMode,
                                    {
                                      monthlyCost: rider.monthlyCost,
                                      annualCost: rider.annualCost,
                                      quarterlyCost: rider.quarterlyCost,
                                      semiAnnualCost: rider.semiAnnualCost,
                                      singlePremiumCost: rider.singlePremiumCost,
                                    },
                                    ComprehensivePricingCurrency.CAD,
                                  )}
                                  type={'rider'}
                                  coverageAmount={rider.coverageAmount}
                                  currency={ComprehensivePricingCurrency.CAD}
                                  isFirstInList={false}
                                />
                              ))}
                            </ComprehensivePricingWidgetProductCard>
                          ))}
                          {party.riders.map((rider: ComprehensivePricingRider, index) => (
                            <ComprehensivePricingWidgetProductCard
                              key={rider.productCode}
                              displayName={rider.displayName?.[language] || rider.productCode}
                              cost={paymentModeToModeToCost(
                                paymentMode,
                                {
                                  monthlyCost: rider.monthlyCost,
                                  annualCost: rider.annualCost,
                                  quarterlyCost: rider.quarterlyCost,
                                  semiAnnualCost: rider.semiAnnualCost,
                                  singlePremiumCost: rider.singlePremiumCost,
                                },
                                ComprehensivePricingCurrency.CAD,
                              )}
                              type={'product'}
                              coverageAmount={rider.coverageAmount}
                              currency={ComprehensivePricingCurrency.CAD}
                              isFirstInList={index === 0 && !party.products.length}
                            />
                          ))}
                        </ComprehensivePricingContractProductsContainer>
                      </Box>
                    </Fragment>
                  ))}
                  {showContractSection && (
                    <Box>
                      <Typography variant='h3' component='h2'>
                        {t('assistedApplication.comprehensivePricingWidget.contract')}
                      </Typography>
                      <ComprehensivePricingContractProductsContainer>
                        {contract.riders.map((rider, index) => (
                          <ComprehensivePricingWidgetProductCard
                            key={rider.productCode}
                            displayName={rider.displayName?.[language] || rider.productCode}
                            coverageAmount={rider.coverageAmount}
                            cost={paymentModeToModeToCost(
                              paymentMode,
                              {
                                monthlyCost: rider.monthlyCost,
                                annualCost: rider.annualCost,
                                quarterlyCost: rider.quarterlyCost,
                                semiAnnualCost: rider.semiAnnualCost,
                                singlePremiumCost: rider.singlePremiumCost,
                              },
                              ComprehensivePricingCurrency.CAD,
                            )}
                            type={'product'}
                            currency={ComprehensivePricingCurrency.CAD}
                            isFirstInList={index === 0}
                          />
                        ))}
                        {contract.fees && !contract.fees.hideFees && (
                          <ComprehensivePricingWidgetProductCard
                            key={'fees'}
                            displayName={t('assistedApplication.comprehensivePricingWidget.policyFees')}
                            cost={paymentModeToModeToCost(
                              paymentMode,
                              {
                                monthlyCost: contract.fees.monthlyCost,
                                annualCost: contract.fees.annualCost,
                                quarterlyCost: contract.fees.quarterlyCost,
                                semiAnnualCost: contract.fees.semiAnnualCost,
                                singlePremiumCost: contract.fees.singlePremiumCost,
                              },
                              ComprehensivePricingCurrency.CAD,
                            )}
                            type={'product'}
                            currency={ComprehensivePricingCurrency.CAD}
                            isFirstInList={contract.riders.length === 0}
                            tooltipType={
                              contract.fees.informationMessage
                                ? ComprehensivePricingWidgetTooltipType.policyFee
                                : undefined
                            }
                            tooltipMessage={contract.fees.informationMessage}
                          />
                        )}
                      </ComprehensivePricingContractProductsContainer>
                    </Box>
                  )}

                  {comprehensivePricingValue.contracts.length > 1 && (
                    <ComprehensivePricingSubtotalContainer
                      isLast={contractIndex === comprehensivePricingValue.contracts.length - 1}
                    >
                      <ComprehensivePricingWidgetProductCard
                        displayName={t('assistedApplication.comprehensivePricingWidget.subtotal')}
                        type={'subTotal'}
                        cost={paymentModeToModeToCost(
                          paymentMode,
                          {
                            monthlyCost: contract.monthlyCost,
                            annualCost: contract.annualCost,
                            quarterlyCost: contract.quarterlyCost,
                            semiAnnualCost: contract.semiAnnualCost,
                            singlePremiumCost: contract.singlePremiumCost,
                          },
                          ComprehensivePricingCurrency.CAD,
                        )}
                        currency={ComprehensivePricingCurrency.CAD}
                      />
                    </ComprehensivePricingSubtotalContainer>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </ComprehensivePricingCardBody>
      {comprehensivePricingValue && !isError && (
        <ComprehensivePricingCardFooterContainer>
          <ComprehensivePricingCardFooter>
            <Typography variant='h3'>{t('assistedApplication.comprehensivePricingWidget.totalPremium')}</Typography>
            <Typography variant='h2'>
              {`${costToCostWithCurrency(
                paymentModeToModeToCost(
                  paymentMode,
                  {
                    monthlyCost: comprehensivePricingValue.monthlyCost,
                    annualCost: comprehensivePricingValue.annualCost,
                    quarterlyCost: comprehensivePricingValue.quarterlyCost,
                    semiAnnualCost: comprehensivePricingValue.semiAnnualCost,
                    singlePremiumCost: comprehensivePricingValue.singlePremiumCost,
                  },
                  ComprehensivePricingCurrency.CAD,
                ) || 0,
                ComprehensivePricingCurrency.CAD,
              )} / `}
              <TimeUnit paymentMode={paymentMode} />
            </Typography>
          </ComprehensivePricingCardFooter>
        </ComprehensivePricingCardFooterContainer>
      )}
    </ComprehensivePricingCardContainer>
  );
}
