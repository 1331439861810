import { Notification, NotificationStatus } from '@breathelife/types';
import { PropsWithChildren, ReactElement, createContext, useEffect, useState } from 'react';
import { useIdleTimer } from '../../../Helpers/idleTimer';
import { useFetchAllNotificationsQuery } from '../../../ReactQuery/NotificationCentre/notifications.queries';

type NotificationCenterContextValue = {
  notifications: Notification[];
  unreadNotificationsCount: number;
  isFetchingNotifications: boolean;
  isNotificationCentreOpen: boolean;
  canLoadMoreNotifications: boolean;
  setNotifications: (notifications: Notification[]) => void;
  refetchNotifications: () => void;
  setIsNotificationCentreOpen: (isOpen: boolean) => void;
  setCursorDate: (cursorDate: Date) => void;
};

export const NotificationCenterContext = createContext<NotificationCenterContextValue>({
  notifications: [],
  unreadNotificationsCount: 0,
  isFetchingNotifications: false,
  isNotificationCentreOpen: false,
  canLoadMoreNotifications: true,
  setNotifications: () => {},
  refetchNotifications: () => {},
  setIsNotificationCentreOpen: () => {},
  setCursorDate: () => {},
});

export function NotificationCentreContextProvider(props: PropsWithChildren<{}>): ReactElement {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0);
  const [isNotificationCentreOpen, setIsNotificationCentreOpen] = useState<boolean>(false);

  const [cursorDate, setCursorDate] = useState<Date>();
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);

  const [canLoadMoreNotifications, setCanLoadMoreNotifications] = useState(true);

  // We stop the automatic refetch for the notifications when the user is idle
  useIdleTimer({
    onIdle: () => setIsUserIdle(true),
    onActive: () => setIsUserIdle(false),
    timeout: 180000,
  });

  const {
    data: fetchedNotifications,
    refetch: refetchNotifications,
    isFetching: isFetchingNotifications,
  } = useFetchAllNotificationsQuery(cursorDate, {
    refetchInterval: () => {
      if (isNotificationCentreOpen || isUserIdle) {
        return false;
      }
      return 120000;
    },
  });

  useEffect(() => {
    const unreadNotifications = notifications.filter((item) => {
      return item.status === NotificationStatus.Unread;
    });
    setUnreadNotificationsCount(unreadNotifications.length);
  }, [notifications, setUnreadNotificationsCount]);

  useEffect(() => {
    if (!fetchedNotifications) return;
    if (fetchedNotifications.length > 0) {
      const currentNotifications =
        notifications.length > 0 ? [...notifications, ...fetchedNotifications] : fetchedNotifications;
      setNotifications(currentNotifications);

      const oldestNotification = currentNotifications.reduce((oldest, current) => {
        return new Date(current.createdAt) < new Date(oldest.createdAt) ? current : oldest;
      });
      setCursorDate(oldestNotification.createdAt);
    } else if (fetchedNotifications.length === 0) {
      setCanLoadMoreNotifications(false);
    }
  }, [fetchedNotifications]);

  return (
    <NotificationCenterContext.Provider
      value={{
        notifications,
        unreadNotificationsCount,
        isFetchingNotifications,
        isNotificationCentreOpen,
        setNotifications,
        refetchNotifications,
        setIsNotificationCentreOpen,
        setCursorDate,
        canLoadMoreNotifications,
      }}
    >
      {props.children}
    </NotificationCenterContext.Provider>
  );
}
